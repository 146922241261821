<template>
  <v-row class="ma-4" v-if="account">
    <v-col cols="12">
      <div>
        <h1
          class="display-1 font-weight-light"
          style="font-family: Avenir !important"
        >
          Billing
        </h1>
      </div>
    </v-col>
    <v-col cols="12">
      <v-card tile>
        <v-card-title>
          Subscription details
          <v-spacer />
          <v-btn large outlined color="primary" :href="account.portalUrl">
            <span
              v-if="
                account.subscription.status === 'trialing' &&
                !account.paymentMethod
              "
            >
              Upgrade now
            </span>
            <span v-else> View billing portal </span>
          </v-btn>
        </v-card-title>
        <v-alert
          type="warning"
          dense
          text
          class="mb-10"
          v-if="
            account.subscription.status === 'trialing' && !account.paymentMethod
          "
        >
          Warning: You currently have not set a payment method. Your access to
          Packagr will stop once your trial period ends. To ensure continued
          access, please use the <strong>Update payment method</strong> button
          below
        </v-alert>
        <v-card-text>
          <v-row>
            <v-col cols="3">Plan</v-col>
            <v-col cols="9">
              <div>
                <p class="my-0 font-weight-bold">{{ account.product.name }}</p>
                <p class="my-0">
                  {{ account.subscription.quantity }} user @
                  {{ account.subscription.plan.amount / 100 }}
                  {{ account.subscription.plan.currency.toUpperCase() }} per
                  user per
                  {{ account.subscription.plan.interval }}
                </p>
              </div>
              <update-subscription-modal
                @change="$apollo.queries.account.refetch()"
              />
            </v-col>

            <v-col cols="3">Subscription ID</v-col>
            <v-col cols="9">{{ account.subscription.id }}</v-col>

            <v-col cols="3">Created</v-col>
            <v-col cols="9">{{
              account.subscription.created | unixDate
            }}</v-col>

            <v-col cols="3">Status</v-col>
            <v-col cols="9">{{ account.subscription.status }}</v-col>

            <v-col cols="3" v-if="account.subscription.status === 'trialing'"
              >Trial end date</v-col
            >
            <v-col cols="9" v-if="account.subscription.status === 'trialing'">{{
              account.subscription.trial_end | unixDate
            }}</v-col>

            <v-col cols="3">Payment method</v-col>
            <v-col cols="9">
              <div v-if="account.paymentMethod">
                {{ account.paymentMethod.card.brand.toUpperCase() }} card ending
                in {{ account.paymentMethod.card.last4 }}
              </div>
              <div v-else>
                <p class="mb-0 error--text font-weight-bold">Not set</p>
              </div>
              <payment-method-modal
                @change="$apollo.queries.account.refetch()"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { queries } from "@/gql";
import { unixDate } from "@/filters";
import PaymentMethodModal from "@/components/dialogs/PaymentMethodModal";
import UpdateSubscriptionModal from "@/components/dialogs/UpdateSubscriptionModal";

const { account } = queries;

export default {
  components: { UpdateSubscriptionModal, PaymentMethodModal },
  apollo: { account },
  filters: { unixDate },
};
</script>
