var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"title":"Choose a plan","max-width":1000},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('default-button',{staticClass:"ml-0",attrs:{"on":on,"color":"success","icon":"fa-edit"}},[_vm._v(" Update plan ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-snackbar',{attrs:{"color":"error"},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v(_vm._s(_vm.errorMessage))]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',[_vm._v("Choose your "),_c('strong',[_vm._v("Billing frequency")])])]),_c('v-col',{attrs:{"cols":"9"}},[_c('v-radio-group',{staticClass:"my-0",model:{value:(_vm.form.billingPeriod),callback:function ($$v) {_vm.$set(_vm.form, "billingPeriod", $$v)},expression:"form.billingPeriod"}},[_c('v-radio',{attrs:{"value":"MONTHLY"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Monthly billing")])]},proxy:true}])}),_c('v-radio',{attrs:{"value":"ANNUAL"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Annual billing "),_c('strong',{staticClass:"success--text"},[_vm._v(" - Save 10%")])])]},proxy:true}])})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('div',[_vm._v("Choose a "),_c('strong',[_vm._v("plan")])])]),_c('v-col',{attrs:{"cols":"9"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-list',{attrs:{"shaped":""}},[_c('v-list-item-group',{model:{value:(_vm.form.tier),callback:function ($$v) {_vm.$set(_vm.form, "tier", $$v)},expression:"form.tier"}},[_c('v-list-item',{attrs:{"three-line":"","value":"basic","active-class":"deep-purple--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Basic plan")]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.getPrice('basic'))}})],1),_c('v-list-item-content',[_c('ul',[_c('li',{staticClass:"grey--text text--darken-3"},[_vm._v(" 1 GB storage per user ")]),_c('li',{staticClass:"grey--text text--darken-3"},[_vm._v(" Access control management ")]),_c('li',{staticClass:"grey--text text--darken-3"},[_vm._v(" Unlimited packages/images ")]),_c('li',{staticClass:"grey--text text--darken-3"},[_vm._v(" Encrypted package storage ")])])]),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"deep-purple accent-4"}})],1)]}}])}),_c('v-divider'),_c('v-list-item',{attrs:{"three-line":"","value":"standard","active-class":"deep-purple--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Standard plan")]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.getPrice('standard'))}})],1),_c('v-list-item-content',[_c('ul',[_c('li',{staticClass:"grey--text text--darken-3"},[_vm._v(" 5 GB storage per user ")]),_c('li',{staticClass:"grey--text text--darken-3"},[_vm._v(" Upstream mirroring ")]),_c('li',{staticClass:"grey--text text--darken-3"},[_vm._v(" Edge Caching ")]),_c('li',{staticClass:"grey--text text--darken-3"},[_vm._v("API access")])])]),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"deep-purple accent-4"}})],1)]}}])}),_c('v-divider'),_c('v-list-item',{attrs:{"three-line":"","value":"advanced","active-class":"deep-purple--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Advanced plan")]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.getPrice('advanced'))}})],1),_c('v-list-item-content',[_c('ul',[_c('li',{staticClass:"grey--text text--darken-3"},[_vm._v(" 30 GB storage per user ")]),_c('li',{staticClass:"grey--text text--darken-3"},[_vm._v(" S3 log export functionality ")]),_c('li',{staticClass:"grey--text text--darken-3"},[_vm._v(" Custom domains ")]),_c('li',{staticClass:"grey--text text--darken-3"},[_vm._v(" SAML authentication ")])])]),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"deep-purple accent-4"}})],1)]}}])})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('div',[_vm._v("Select the number of "),_c('strong',[_vm._v("seats")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"persistent-hint":"","hint":"The more seats you have, the more team members you can add to your account","dense":"","outlined":"","label":"User count","rules":[_vm.rules.required, _vm.rules.numeric],"type":"number"},model:{value:(_vm.form.quantity),callback:function ($$v) {_vm.$set(_vm.form, "quantity", $$v)},expression:"form.quantity"}})],1),_c('v-col',{attrs:{"cols":"5"}}),_c('v-col',{attrs:{"cols":"3"}},[_c('div',[_c('strong',[_vm._v("TOTAL PRICE")])])]),_c('v-col',{attrs:{"cols":"9"}},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.totalPrice))])])])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('default-button',{attrs:{"disabled":!_vm.valid,"loading":_vm.loading},on:{"click":_vm.handleSave}},[_vm._v("Save changes")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }