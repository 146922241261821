<template>
  <Dialog v-model="dialog" title="Choose a plan" :max-width="1000">
    <v-snackbar v-model="error" color="error">{{ errorMessage }}</v-snackbar>
    <template v-slot:activator="{ on }">
      <default-button :on="on" color="success" icon="fa-edit" class="ml-0">
        Update plan
      </default-button>
    </template>
    <v-card-text>
      <v-form v-model="valid">
        <v-row>
          <v-col cols="3">
            <div>Choose your <strong>Billing frequency</strong></div>
          </v-col>
          <v-col cols="9">
            <v-radio-group v-model="form.billingPeriod" class="my-0">
              <v-radio value="MONTHLY">
                <template v-slot:label>
                  <div>Monthly billing</div>
                </template>
              </v-radio>
              <v-radio value="ANNUAL">
                <template v-slot:label>
                  <div>
                    Annual billing
                    <strong class="success--text"> - Save 10%</strong>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="3">
            <div>Choose a <strong>plan</strong></div>
          </v-col>
          <v-col cols="9">
            <v-card class="mx-auto">
              <v-list shaped>
                <v-list-item-group v-model="form.tier">
                  <v-list-item
                    three-line
                    value="basic"
                    active-class="deep-purple--text text--accent-4"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title>Basic plan</v-list-item-title>
                        <v-list-item-subtitle v-text="getPrice('basic')" />
                      </v-list-item-content>

                      <v-list-item-content>
                        <ul>
                          <li class="grey--text text--darken-3">
                            1 GB storage per user
                          </li>
                          <li class="grey--text text--darken-3">
                            Access control management
                          </li>
                          <li class="grey--text text--darken-3">
                            Unlimited packages/images
                          </li>
                          <li class="grey--text text--darken-3">
                            Encrypted package storage
                          </li>
                        </ul>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          color="deep-purple accent-4"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                  <v-divider />
                  <v-list-item
                    three-line
                    value="standard"
                    active-class="deep-purple--text text--accent-4"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title>Standard plan</v-list-item-title>
                        <v-list-item-subtitle v-text="getPrice('standard')" />
                      </v-list-item-content>

                      <v-list-item-content>
                        <ul>
                          <li class="grey--text text--darken-3">
                            5 GB storage per user
                          </li>
                          <li class="grey--text text--darken-3">
                            Upstream mirroring
                          </li>
                          <li class="grey--text text--darken-3">
                            Edge Caching
                          </li>
                          <li class="grey--text text--darken-3">API access</li>
                        </ul>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          color="deep-purple accent-4"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                  <v-divider />
                  <v-list-item
                    three-line
                    value="advanced"
                    active-class="deep-purple--text text--accent-4"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title>Advanced plan</v-list-item-title>
                        <v-list-item-subtitle v-text="getPrice('advanced')" />
                      </v-list-item-content>

                      <v-list-item-content>
                        <ul>
                          <li class="grey--text text--darken-3">
                            30 GB storage per user
                          </li>
                          <li class="grey--text text--darken-3">
                            S3 log export functionality
                          </li>
                          <li class="grey--text text--darken-3">
                            Custom domains
                          </li>
                          <li class="grey--text text--darken-3">
                            SAML authentication
                          </li>
                        </ul>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          color="deep-purple accent-4"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>

          <v-col cols="3">
            <div>Select the number of <strong>seats</strong></div>
          </v-col>
          <v-col cols="4">
            <v-text-field
              persistent-hint
              hint="The more seats you have, the more team members you can add to your account"
              dense
              outlined
              label="User count"
              v-model="form.quantity"
              :rules="[rules.required, rules.numeric]"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="5"></v-col>
          <v-col cols="3">
            <div><strong>TOTAL PRICE</strong></div>
          </v-col>
          <v-col cols="9">
            <div>
              <strong>{{ totalPrice }}</strong>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <default-button :disabled="!valid" :loading="loading" @click="handleSave"
        >Save changes</default-button
      >
    </v-card-actions>
  </Dialog>
</template>

<script>
import { mutations } from "@/gql";
import Dialog from "@/components/dialogs/Dialog";
import DefaultButton from "@/components/widgets/DefaultButton";

const { updateSubscription: mutation } = mutations;

export default {
  components: { DefaultButton, Dialog },
  data: () => ({
    dialog: false,
    valid: null,
    error: false,
    errorMessage: null,
    loading: false,
    form: {
      billingPeriod: "ANNUAL",
      tier: "standard",
      quantity: 3,
    },
    rules: {
      required: (v) => !!v || "This field is required",
    },
  }),
  computed: {
    totalPrice() {
      const price = this.getPriceValue(this.form.tier) * this.form.quantity;
      return `£${price} per ${
        this.form.billingPeriod === "ANNUAL" ? "year" : "month"
      }`;
    },
  },
  methods: {
    getPriceValue(tier) {
      const mapping = {
        ANNUAL: {
          basic: 100,
          standard: 290,
          advanced: 690,
        },
        MONTHLY: {
          basic: 12,
          standard: 29,
          advanced: 69,
        },
      };
      return mapping[this.form.billingPeriod][tier];
    },
    getPrice(tier) {
      const price = this.getPriceValue(tier);
      return `£${price} per user per ${
        this.form.billingPeriod === "ANNUAL" ? "year" : "month"
      }`;
    },
    handleSave() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: this.form,
        })
        .then((result) => {
          if (result) {
            this.loading = false;
            this.dialog = false;
            this.$emit("change");
          }
        })
        .catch((error) => {
          this.error = true;
          this.loading = false;
          this.errorMessage = error.message.replace("GraphQL error: ", "");
        });
    },
  },
};
</script>

<style scoped>
.v-application pre code.code {
  all: unset;
}
</style>
