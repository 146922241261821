<template>
  <Dialog v-model="dialog" title="Update payment method">
    <v-snackbar v-model="error" color="error">{{ errorMessage }}</v-snackbar>
    <template v-slot:activator="{ on }">
      <default-button :on="on" color="success" icon="fa-edit" class="ml-0">
        Update payment method
      </default-button>
    </template>
    <v-card-text>
      <v-form v-model="valid">
        <v-row>
          <v-col cols="12" class="py-0"
            ><v-text-field-simplemask
              v-bind:properties="{
                prefix: '',
                suffix: '',
                readonly: false,
                dense: true,
                disabled: false,
                outlined: true,
                rules: [rules.cardLength, rules.numeric, rules.required],
                clearable: true,
                placeholder: '#### #### #### ####',
              }"
              v-bind:options="{
                inputMask: '#### #### #### ####',
                outputMask: '################',
                empty: null,
                applyAfter: false,
                alphanumeric: true,
                lowerCase: false,
              }"
              v-model="form.cardNumber"
              v-bind:focus="focus"
              v-on:focus="focus = false"
              label="Card number"
            />
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <v-select
              dense
              outlined
              :items="months"
              v-model="form.expMonth"
              label="Expiry (month)"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <v-select
              dense
              outlined
              :items="years"
              v-model="form.expYear"
              label="Expiry (year)"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <v-text-field
              dense
              outlined
              v-model="form.cvc"
              label="CVC"
              :rules="[rules.cvcLength, rules.numeric, rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <default-button :disabled="!valid" :loading="loading" @click="handleSave"
        >Save changes</default-button
      >
    </v-card-actions>
  </Dialog>
</template>

<script>
import { mutations } from "@/gql";
import Dialog from "@/components/dialogs/Dialog";
import DefaultButton from "@/components/widgets/DefaultButton";

const { setPaymentMethod: mutation } = mutations;

export default {
  components: { DefaultButton, Dialog },
  data: () => ({
    dialog: false,
    focus: false,
    valid: null,
    error: false,
    errorMessage: null,
    months: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ],
    years: [
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
    ],
    loading: false,
    form: {
      cardNumber: null,
      expMonth: null,
      expYear: null,
      cvc: null,
    },
    rules: {
      cardLength: (v) => (v && v.length === 19) || `Must be 16 characters long`,
      cvcLength: (v) => (v && v.length === 3) || "Must be 3 characters long",
      numeric: (v) => !isNaN(parseFloat(v)) || "Must only contain numbers",
      required: (v) => !!v || "This field is required",
    },
  }),
  methods: {
    handleSave() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: this.form,
        })
        .then((result) => {
          if (result) {
            this.loading = false;
            this.dialog = false;
            this.$emit("change");
          }
        })
        .catch((error) => {
          this.error = true;
          this.loading = false;
          this.errorMessage = error.message.replace("GraphQL error: ", "");
        });
    },
  },
};
</script>

<style scoped>
.v-application pre code.code {
  all: unset;
}
</style>
